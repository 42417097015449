import { action } from 'satcheljs';

import { TLanguageCode } from '../type/language-code.type';

export const addResourceBundleAction = action(
  'addResourceBundleAction',
  (language: TLanguageCode, namespace: string, resources: any, deep?: boolean | undefined, overwrite?: boolean | undefined) => {
    return {
      language,
      namespace,
      resources,
      deep,
      overwrite
    };
  }
);
