import { orchestrator } from 'satcheljs';

import { changeDomLanguageAction } from '../action/change-dom-language.action';

orchestrator(changeDomLanguageAction, actionMessage => {
  const { language } = actionMessage;

  const htmlElement = document.querySelector('html');

  if (!htmlElement) return;

  language ? htmlElement.setAttribute('lang', language) : htmlElement.removeAttribute('lang');
});
