import { createStore } from 'satcheljs';

import { ILanguageStore } from './language.type';

const initStore: ILanguageStore = {
  language: undefined,
  i18n: undefined
};

export const getStore = createStore('store-language', initStore);
