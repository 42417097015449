import i18next, { i18n } from 'i18next';
import Http from 'i18next-http-backend';
import BackendAdapter, { MultiloadBackendOptions } from 'i18next-multiload-backend-adapter';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';

import { SecureLocalStorage } from '@totopkg/shared-util-local-storage';

import { changeLanguageAction } from '../../store-language/action/change-language.action';
import { DEFAULT_LANGUAGE_CODE, LANGUAGE_DEFAULT_NS, LOCAL_LANGUAGE_KEY } from '../../store-language/constant/language.constant';
import { setI18nAction } from '../../store-language/mutator-action/set-i18n.action';
import { setLanguageAction } from '../../store-language/mutator-action/set-language.action';
import { i18nSelector } from '../../store-language/selector/i18n.selector';
import { TLanguageCode } from '../../store-language/type/language-code.type';
import { ILanguageInitOptions } from './use-language-init.type';

export const useLanguageInit = (options?: ILanguageInitOptions) => {
  const { languageDefault, resourcePath = '' } = options || {};

  const [initCompleted, setInitCompleted] = useState<boolean>(false);

  useEffect(() => {
    const _localLanguage = SecureLocalStorage.getItem<TLanguageCode>(LOCAL_LANGUAGE_KEY, false);

    /** detect default `lang` if no `lang` select before */
    if (languageDefault && !_localLanguage) {
      changeLanguageAction(languageDefault);
      return;
    }

    /** Create new i18n instance from backend resources loader */
    const _i18n: i18n = i18next
      .createInstance(
        {
          fallbackLng: _localLanguage ?? languageDefault ?? DEFAULT_LANGUAGE_CODE
        },
        (error, t) => {
          return t('translateError');
        }
      )
      .use(initReactI18next)
      .use(BackendAdapter);
    /** set language value */
    setLanguageAction(_localLanguage);
    _i18n.changeLanguage(_localLanguage);

    if (!resourcePath) return setInitCompleted(true);

    /** Load backend resource */
    _i18n.init<MultiloadBackendOptions>(
      {
        ns: LANGUAGE_DEFAULT_NS,
        defaultNS: LANGUAGE_DEFAULT_NS,
        backend: {
          backend: Http,
          backendOption: {
            loadPath: resourcePath,
            allowMultiLoading: true
          }
        }
      },
      () => {
        /** change 18n instance */
        setI18nAction(_i18n);
        setInitCompleted(true);
      }
    );
  }, [languageDefault, resourcePath]);

  return {
    initLanguageCompleted: initCompleted && !!i18nSelector()
  };
};
