import { observer } from 'mobx-react';
import React from 'react';
import { Trans } from 'react-i18next';

import { i18nSelector } from '../../store-language/selector/i18n.selector';
import { ITranslatedMessageProps } from './translated-message.type';

export const TranslatedMessage: React.FC<ITranslatedMessageProps> = observer(props => {
  const { id, options } = props;

  const i18n = i18nSelector();

  return <Trans i18n={i18n} i18nKey={id} options={options} {...props} />;
});
