import { orchestrator } from 'satcheljs';

import { addResourceBundleAction } from '../action/add-resource-bundle.action';
import { getStore } from '../store/language.store';

orchestrator(addResourceBundleAction, actionMessage => {
  const { language, namespace, resources, deep, overwrite } = actionMessage;

  getStore().i18n?.addResourceBundle(language, namespace, resources, deep, overwrite);
});
