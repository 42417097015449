import { orchestrator } from 'satcheljs';

import { SecureLocalStorage } from '@totopkg/shared-util-local-storage';

import { changeLanguageAction } from '../action/change-language.action';
import { LOCAL_LANGUAGE_KEY } from '../constant/language.constant';
import { languageSelector } from '../selector/language.selector';

orchestrator(changeLanguageAction, actionMessage => {
  const { language } = actionMessage;

  const _prevLanguage = languageSelector();
  if (language === _prevLanguage) return;

  SecureLocalStorage.setItem(LOCAL_LANGUAGE_KEY, language, false);
  window.location.reload();
});
