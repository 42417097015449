import { i18n } from 'i18next';
import { Trans } from 'react-i18next';

export interface ITranslatedMessageProps {
  id: string | string[] | undefined;
  values?: React.ComponentProps<typeof Trans>['values'];
  options?: Parameters<i18n['t']>[1];
  components?: React.ComponentProps<typeof Trans>['components'];
  children?: React.ReactNode;
}
