import { i18n, TOptions } from 'i18next';

import { LANGUAGE_DEFAULT_NS } from '../constant/language.constant';
import { i18nSelector } from '../selector/i18n.selector';

export const translate = (key: Parameters<i18n['t']>[0] | undefined, options?: Parameters<i18n['t']>[1]) => {
  if (!key) return '';

  const _i18n = i18nSelector();

  let _options: TOptions;
  if (typeof options === 'string') {
    _options = {
      defaultValue: _i18n?.t(key, {
        ns: LANGUAGE_DEFAULT_NS,
        defaultValue: options ?? key
      })
    };
  } else {
    _options = {
      ...options,
      defaultValue: _i18n?.t(key, {
        ns: LANGUAGE_DEFAULT_NS,
        defaultValue: options?.defaultValue ?? key
      })
    };
  }

  return _i18n?.t(key, _options);
};
